import React from 'react'
// import './NotFound.css'

function PrivacyPolicy() {
    return (
        <div className="home container">
            <div className="NotFound-div">
                <h1>Device Recommendations links</h1>

                <p>Web cam Razer Kiyp : <a href="https://amzn.to/3ODkonZ">https://amzn.to/3ODkonZ</a></p>
                <p>Monitor stand : <a href="https://amzn.to/3vh1zj6">https://amzn.to/3vh1zj6</a></p>

                
            </div>

        </div>
    )
}

export default PrivacyPolicy

